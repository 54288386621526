@import '../node_modules/@syncfusion/ej2/tailwind.css';
@import '../node_modules/@syncfusion/ej2-base/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-icons/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-angular-grids/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-angular-buttons/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-angular-calendars/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-angular-documenteditor/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-angular-inputs/styles/tailwind.css';
@import '../node_modules/@syncfusion/ej2-angular-pdfviewer/styles/tailwind.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import './assets/styles.css';
@import '../node_modules/ag-grid-community/styles/ag-grid.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-alpine.css';
@import './assets/css/z-index.css';

/* You can add global styles to this file, and also import other style files */

/* Message Service Customization */
.ant-message {
  z-index: var(--z-notification) !important;
  bottom: 100px !important;
  top: auto !important;
}
:host ::ng-deep .cdk-overlay-container,
:host ::ng-deep .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  z-index: var(--z-cdk-overlay) !important;
}
.ant-message-notice {
  padding: 0 !important;
  margin-bottom: 16px !important;
}

.ant-message-notice-content {
  transform: translateX(100%);
  animation: slideIn 0.3s forwards;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 12px 20px;
}

@keyframes slideIn {
  to {
    transform: translateX(0);
  }
}

/* Ensure message is above other elements */
.ant-message-notice-content {
  position: relative;
  z-index: var(--z-notification);
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif !important;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.row.content > * {
    width: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

a:hover {
    text-decoration: none;
    cursor: pointer;
}

.capitalize {
    text-transform: capitalize;
}

.form-group.valign {
    display: flex;
    align-items: center;
}
.border {
    border-style: solid !important;
    border-width: thin !important;
    border-color: cadetblue !important;
}
@media (min-width: 1367px) {
    .valign input {
        margin-top: 7px;
    }
}
:host ::ng-deep .e-dlg-container.e-kanban-dialog.e-dlg-center-center,
.e-popup.e-popup-open.e-dialog,
.e-lib.e-dialog.e-control.e-kanban-dialog.e-dlg-modal.e-popup.e-popup-open {
    z-index: var(--z-dialog) !important;
}

.text-color {
    color: white !important;
}

.danger-color {
    color: red !important;
}
.header-color {
    background-color: #0a2c38 !important;
    color: white !important;
}
.gray-gradient {
    background-image: linear-gradient(to top, #fff, gainsboro) !important;
    color: black !important;
}

.primary-color {
    background-color: #0a2c38 !important;
    color: white !important;
}

.secondary-color {
    background-color: #114a59 !important;
    color: white !important;
}

.tertiary-color {
    background-color: #248097 !important;
    color: white !important;
}

.alternating-gradient {
    background-image: linear-gradient(145deg, #104051 50%, #0a2c38 50%) !important;
}

.green-gradient {
    background-image: linear-gradient(to top, #3ab0d8, #248097) !important;
    color: white !important;
}
.e-excelexport {
    color: green !important;
}

.e-pdfexport {
    color: red !important;
}

.light-blue-gradient {
    background-image: linear-gradient(to bottom, #a2bfe6, #ccedfe) !important;
}

.blue-gradient {
    background-image: linear-gradient(to top, #001a54, #3d72b4) !important;
    color: aliceblue !important;
}

.yellow-gradient {
    background-image: linear-gradient(to top, white, yellow) !important;
    color: black !important;
}

.red-gradient {
    color: white !important;
    background-image: linear-gradient(to top, #272822 0%, #b62121 74%) !important;
}

.font-awesome-primary {
    font-size: medium !important;
    font-weight: 900;
    color: #001a54 !important;
    border: none !important;
}

.font-awesome-danger {
    font-size: medium !important;
    font-weight: 900;
    color: #b62121 !important;
    font-family: 'fontawesome';
    border: none !important;
}

.font-awesome-primary:hover,
.font-awesome-danger:hover {
    cursor: pointer !important;
}

.font-awesome > .e-toast > .e-toast-icon {
    font-size: medium !important;
    font-weight: 900 !important;
    font-family: 'Font Awesome 6 Free' !important;
}

.successIcon {
    content: '\e72b';
    font-weight: 900;
    font-family: 'e-icons';
}

button > span:hover {
    cursor: pointer;
}
.fontawesome {
    font-size: medium !important;
    font-weight: 900 !important;
    font-family: 'Font Awesome 6 Free' !important;
}

.e-dashboardlayout.e-control .e-panel .e-panel-container .e-panel-header,
.e-dialog .e-dlg-header-content {
    background-color: #114a59 !important;
}

.e-dashboardlayout.e-control .e-panel {
    height: fit-content !important;
}

.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn,
.e.dialog .e-dlg-header,
.e-dlg-header,
.e-dialog .e-dlg-header-content,
.e-dialog .e-icon-dlg-close {
    color: white !important;
}
.e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]) {
    background-image: linear-gradient(to top, #2c677b, #248097) !important;
    color: white !important;
}

.full-with-dialog {
    position: relative;
    overflow: auto;
    width: 85vw;

    @media (max-width: 568px) {
        width: 70vw;
    }

    @media (max-width: 468px) {
        width: 50vw;
    }

    @media (max-width: 368px) {
        width: 35vw;
    }
}

.statustemp.e-inactivecolor,
.statustemp.e-pendingcolor,
.statustxt.e-completecolor {
    background-color: #ffd7cc !important;
    width: 64px;
}

.statustemp.e-activecolor {
    background-color: #ccffcc !important;
    width: 57px;
}

.statustxt.e-activecolor {
    color: #00cc00 !important;
    position: relative;
}

.statustxt.e-pendingcolor {
    color: #f5c330 !important;
    position: relative;
}

.statustxt.e-completecolor {
    color: #175a6b !important;
    position: relative;
}

.statustxt.e-inactivecolor {
    color: #e60000 !important;
    position: relative;
}

.statustemp {
    position: relative;
    height: 19px;
    border-radius: 5px;
    text-align: center;
}

.steps-content {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 60vh;
}

.ant-result-title {
    white-space: pre-line;
}

.control-section.template {
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 350px;
}

.ant-btn {
    border-radius: 8px !important;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.ant-btn-background-ghost {
    border: none !important;
}

.ant-btn.ant-btn-background-ghost:active,
.ant-btn.ant-btn-background-ghost:focus {
    color: white;
}

.ant-btn-primary {
    color: #fff !important;
    background: linear-gradient(to top, #2c677b, #248097) !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    display: inline-block !important;
    text-decoration: none !important;
    text-align: center !important;
    border-radius: 8px !important;
    border: 1px #06246a !important;
}

.ant-btn-primary:hover {
    background: linear-gradient(to bottom, #2c677b, #248097) !important;
    color: #fff !important;
    text-decoration: none !important;
    cursor: pointer;
}
.autocompleteOff {
    -webkit-text-security: disc;
}
.ant-btn-primary[disabled] {
    color: darkgray !important;
    opacity: 0.4 !important;
    pointer-events: none !important;
}

.ant-btn-dangerous {
    background: linear-gradient(to bottom, #e31837 0%, #554343 100%) !important;
    border: 1px #e31837 !important;
    color: #fff !important;
    min-width: 110px;
    width: auto !important;
    display: inline-block !important;
    margin-left: 6px !important;
    text-decoration: none !important;
    text-align: center !important;
    border-radius: 8px !important;
}

.ant-btn-dangerous:hover {
    background: linear-gradient(to top, #e31837 0%, #554343 100%) !important;
    text-decoration: none !important;
    border-color: #e31837 !important;
    cursor: pointer;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: #082f5f !important;
}

.ant-form {
    width: -moz-available !important; /* WebKit-based browsers will ignore this. */

    width: stretch !important; /* Mozilla-based browsers will ignore this. */
    width: stretch !important;
}

.anticon,
.anticon svg {
    display: inline-grid !important;
}

.ant-steps-item:last-child .ant-steps-item-content {
    padding-right: 20px;
}

@media only screen and (max-width: 453px) and (min-width: 200px) {
    .title-modal {
        font-size: 15px;
    }

    .smallscreen {
        padding: 10px;
    }

    :host ::ng-deep .ant-picker {
        width: 100% !important;
    }
}

@media only screen and (max-width: 600px) and (min-width: 454px) {
    .title-modal {
        font-size: 15px;
    }

    .smallscreen {
        padding: 10px;
    }

    :host ::ng-deep .ant-picker {
        width: 100% !important;
    }
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.disable-cl,
.disable-cl .ant-input,
.disable-cl select,
.disable-cl nz-date-picker > .ant-picker > input {
    background-color: #eee !important;
    pointer-events: none;
}

.disable-cl:hover,
.disable-cl .ant-input:hover,
.disable-cl select:hover,
.disable-cl nz-date-picker:hover {
    cursor: not-allowed;
}

.disable-cl > input[readonly],
input[readonly='readonly'] {
    background-color: #f5f5f5;
    opacity: 0.5;
}

.disable-cl > input[readonly]:hover,
input[readonly='readonly']:hover,
input[readonly='readonly']:hover > .ant-input,
input[readonly]:hover > .ant-input {
    border-color: transparent;
    border: none;
    cursor: not-allowed;
}

input[readonly],
input[readonly='readonly'] {
    background-color: #f5f5f5;
    opacity: 0.5;
}

input[readonly]:hover,
input[readonly='readonly']:hover,
input[readonly='readonly']:hover > .ant-input,
input[readonly]:hover > .ant-input {
    border-color: transparent;
    border: none;
    cursor: not-allowed;
}

mat-dialog-container {
    max-width: 90% !important;
    margin: auto !important;
}

@media (max-width: 500px) {
    mat-dialog-container {
        max-width: 80vw !important;
    }
}

.mat-dialog-override {
    height: 0px;
}

.mat-mdc-dialog-content {
    display: contents !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    display: inline-table !important;
    width: 100%;
    height: 100%;
}

.mat-dialog-override mat-dialog-container > :first-child {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.mat-dialog-override mat-dialog-container mat-dialog-content,
.mat-dialog-override mat-dialog-container div[mat-dialog-content] {
    flex-grow: 1;
    max-height: unset;
}

ul,
ol {
    margin: 0 !important;
    padding: 0 !important;
    list-style: none;
}

img {
    outline: none;
    border: none;
}

.myform .required:after {
    content: ' *';
    color: red;
    font-weight: 500;
}

.login {
    padding: 6rem 6rem 12rem;
    position: relative;
}

.floating-bl {
    position: absolute;
}

.login h1 {
    text-align: center;
    font-weight: 600;
    margin-bottom: 7rem;
    padding-top: 2.8rem;
}

.form-control,
.form-select {
    background-color: #f3f3f3;
    border: 0;
    height: 53px;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

.form-label {
    font-weight: 400;
    font-size: 0.8rem;
    color: #949494;
    margin-bottom: 0.3rem;
}

.login .login-form {
    padding: 0 6rem 0;
    border-bottom: 2px #f3f3f3 solid;
}

.btn-login,
.btn-save {
    height: 53px;
    background-color: #248097;
    font-weight: 600;
    color: #000;
    width: 100%;
    font-size: 1rem;
    display: inline-block;
    border-radius: 0.375rem;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    line-height: 2.5rem;
    padding: 0.375rem 0.75rem;
}

.btn-login:hover,
.btn-save:hover {
    background-color: #142e4b;
    color: #fff;
    border-color: #142e4b;
    cursor: pointer;
}

.forgetpassword {
    text-align: center;
    padding-bottom: 2rem;
}

.forgetpassword a {
    font-weight: 600;
    font-size: 0.68rem;
    text-decoration: none;
    color: #2c677b;
}

.forgetpassword a:hover {
    text-decoration: underline;
    cursor: pointer;
}

.login .signup-form {
    padding: 0 6rem 0;
}

.btn-signup,
.btn-discard {
    height: 53px;
    background-color: #fff;
    font-weight: 600;
    color: #000;
    width: 100%;
    font-size: 1rem;
    border: 1px #9fccaf solid;
    display: inline-block;
    border-radius: 0.375rem;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    line-height: 2.5rem;
    padding: 0.375rem 0.75rem;
}

.btn-signup:hover,
.btn-discard:hover {
    background-color: #9fccaf;
    color: #fff;
    border-color: #9fccaf;
    cursor: pointer;
}

.signup-form h2 {
    text-align: center;
    font-weight: 600;
    color: #142e4b;
    font-size: 1.3rem;
    padding: 4rem 0 2rem;
}

.floating-bl {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 45%;
}

.password-control {
    position: relative;
}

.password-control .form-control {
    padding-right: 9%;
}

.eye-icon {
    position: absolute;
    right: 2%;
    top: 36px;
    width: 32px;
}

.eye-icon svg {
    width: 32px;
    height: 32px;
}

.e-sidebar {
    overflow: hidden !important;
}

.navbar-bl {
    background-color: #104051;
    height: 100vh;
    transition: all 0.5s ease-in-out;
}

.navbar-bl > ul {
    overflow-y: auto;
    height: 100vh;
}

.cotnent-side {
    position: relative;
}

.sidemenu-btn {
    background: transparent;
    border: none;
    position: absolute;
    left: 20px;
    top: 14px;
    z-index: 8;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: #ccc;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

:-webkit-scrollbar-corner {
    background: #000;
}

.navbar-bl ul li {
    text-align: center;
    padding: 0;
}

.navbar-bl ul li a {
    color: #fff;
    font-size: 0.8rem;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    text-decoration: none;
    padding-left: 20%;
}

.e-close .navbar-bl ul li a p {
    display: none;
}

.sidebar-logo {
    background: transparent;
    text-align: center;
    height: 60px;
}

.sidebar-logo img {
    max-width: 100%;
    margin: 0 auto;
    max-height: 80px;
}

.mobile-logo {
    display: none;
}

.e-close .mobile-logo {
    display: block;
}

.square {
    width: 105px;
    line-height: 9;
    max-width: 100%;
    margin: 0 auto;
    max-height: max-content;
    height: auto;
    align-self: center;
}

.e-close .navbar-bl li a i {
    margin: 0 auto !important;
    font-size: 20px;
}

.navbar-bl ul li a.active-navbar {
    background: #248097;
}

.navbar-bl ul li a span {
    display: flex;
    align-items: center;
}

li.active-navbar a {
    background-color: linear-gradient(to top, #2c677b, #248097) !important;
}

li.active-navbar a span {
    width: 35px;
    height: 35px;
    background-color: linear-gradient(to top, #2c677b, #248097);
    display: block;
    color: #fff !important;
    border-radius: 50px;
    line-height: 35px;
    text-align: center;
}

.navbar-bl li a:hover i {
    color: #fff !important;
    cursor: pointer;
}

.navbar-bl li .ant-badge {
    display: block;
}

.navbar-bl li a i {
    font-size: 18px;
    color: #fff !important;
    margin-right: 15px;
}

li > a.active-navbar i {
    background-image: linear-gradient(to top, #2c677b, #248097);
    color: #fff !important;
    -webkit-background-clip: text;
    background-clip: text;
}

/* .navbar-bl li a img {
   width: 17px;
} */
header {
    position: fixed;
    z-index: 2;
    display: flex;
    align-items: center;
    padding: 0;
    -webkit-box-shadow: 0px 8px 6px 0px rgba(191, 191, 191, 0.15);
    -moz-box-shadow: 0px 8px 6px 0px rgba(191, 191, 191, 0.15);
    box-shadow: 0px 8px 6px 0px rgba(191, 191, 191, 0.15);
    top: 0;
    left: 0;
    width: 100%;
}

header h1,
.home-title-mb {
    color: #142e4b;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
}

.searchbar {
    width: 178px;
    height: 40px;
    background-color: #f3f3f3;
    border-radius: 5px;
    display: flex;
    position: relative;
    margin-right: 5px;
}

.search-control {
    border: 0;
    width: 100%;
    background-color: transparent !important;
    font-size: 0.8rem;
    padding: 0 0 0 43px;
    outline: none;
}

.search-btn {
    position: absolute;
    left: 14px;
    top: 8px;
    color: #777777;
    font-size: 1rem;
}

.notification-bl li {
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color: #f3f3f3;
    border-radius: 50px;
    text-align: center;
    line-height: 50px;
    margin-left: 5px;
}

.notification-bl li a {
    display: block;
}

.notification-bl li a i {
    color: #2c677b;
    font-size: 20px;
}

.user-dropdown {
    margin-left: 40px;
}

.user-dropdown span {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    display: inline-block;
    background-color: #f1f1f1;
    text-align: center;
    line-height: 70px;
}

.user-dropdown span i {
    font-size: 26px;
    color: #dadada;
}

.notification-article {
    width: 100%;
    margin-bottom: 40px;
}

.notification-article h2 {
    background-color: #2c677b;
    min-height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    font-weight: 400;
    font-size: 1.4rem;
    padding: 0 15px;
}

.notification-article h2 span {
    width: 34px;
    height: 34px;
    background-color: #248097;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    line-height: 34px;
    font-size: 18px;
}

.notification-content {
    background-color: #f7f7f7;
    min-height: 325px;
    padding: 0 15px;
    position: relative;
}

.notification-content h3 {
    text-align: center;
    color: #5a5858;
    font-size: 0.8rem;
    font-weight: 400;
    padding: 9px 0;
}

.notification-content li {
    border: 2px #f3f3f3 solid;
    border-radius: 3px;
    min-height: 70px;
    background-color: #fff;
    margin-bottom: 9px;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.notification-content li a {
    text-decoration: none;
    color: #000;
}

.notification-content li p {
    font-size: 0.8rem;
    font-weight: 400;
    padding: 6px 0.9rem;
}

.job-title {
    padding: 0.3rem 0.9rem;
    display: flex;
    justify-content: space-between;
    background-color: #e7f1ea;
    font-size: 0.9rem;
    font-weight: 600;
}

.arrow-btn {
    position: absolute;
    bottom: 19px;
    left: 0;
    width: 100%;
    text-align: center;
}

.arrow-btn a {
    color: #2c677b;
    font-size: 16px;
    margin: 0 30px;
}

.sky-bg h2 {
    background-color: #14abb5;
}

.skyligt-bg h2 {
    background-color: #c9e7e9;
    color: #5a5858;
}

.skyligt-bg h2 span {
    background-color: #2c677b;
}

.name-info {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    padding: 10px 0.9rem 5px;
    font-weight: 600;
}

.name-info span:last-child {
    font-weight: 400;
}

.assessments-bl h2 {
    background-color: #142e4b;
}

.assessments-bl .job-title {
    background-color: #f3f3f3;
}

.assessments-bl .name-info span:last-child {
    font-weight: 600;
}

.assessments-bl .notification-content li {
    padding: 5px 12px 15px;
}

.calendar-bl {
    padding-left: 5px !important;
    align-self: baseline;
}

.calendar-bl h2 {
    background-color: #248097;
}

.calendar-bl h2 span {
    background-color: #2c677b;
}

.calendar-content {
    background-color: #f7f7f7;
    padding: 0 20px 20px 10px;
}

.calendar-btn {
    text-align: center;
    color: #5a5858;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 19px 0 10px;
}

.calendar-btn a {
    color: #2c677b;
    font-size: 16px;
    margin: 0 15px;
}

.calendar-table {
    position: relative;
    width: 100%;
}

.calendar-table th {
    font-size: 0.8rem;
    font-weight: 400;
    height: 52px;
    padding-right: 13px;
    text-align: right;
}

.calendar-table td {
    border: 1px #d5d5d5 solid;
    background-color: #fff;
    width: 89.5%;
    text-align: center;
    padding: 0;
}

.appointment-info {
    background-color: #f3f3f3;
    border-radius: 5px;
    display: inline-block;
    width: 60%;
    height: 51px;
    font-size: 0.9rem;
    font-weight: 600;
    padding-top: 15px;
}


.table-cleint {
    background-color: #e7f2eb;
    height: 207px;
    position: absolute;
    width: 54%;
    left: 50%;
    margin-left: -21.5%;
    margin-top: -26px;
}

.click-nav-btn {
    background-color: #14abb5;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    position: absolute;
    color: #000;
    text-align: center;
    line-height: 30px;
    left: 88px;
}

.hide-navbar {
    margin-left: -106px;
}

.hide-navbar a.click-nav-btn {
    left: 102px;
}

.main-wrapper.pad-min {
    transition: all 350ms ease-in-out;
    padding-left: 0px !important;
}
.visible {
    z-index: 100001 !important;
    top: 5px;
    position: relative;
}
.main-wrapper {
    padding: 30px 55px 60px;
}
@media only screen and (max-width: 1366.98px) {
    .main-wrapper {
        padding: 0;
    }
}
.listcontent {
    height: calc(100vh - 65px);
}
.breadcrumb-wrapper {
    padding: 70px 1px 1px 115px;
}

.search-btn-mb {
    display: none;
    color: #777777;
    font-size: 1.3rem;
    margin-right: 10px;
}

.search-btn-mb i {
    color: #777777;
}

.home-title-mb {
    display: none;
    padding: 0 0 20px;
}

.logo-mb {
    display: none;
}

.modal-content {
    border-radius: 10px;
}

.btn-close {
    position: absolute;
    right: 18px;
    top: 10px;
    color: #2c677b;
    font-size: 1.3rem;
    opacity: 1;
    background: none;
    padding: 0;
    z-index: 99;
    width: auto;
    height: auto;
    outline: none;
}

.btn-close img {
    width: 28px;
}

.modal-dialog {
    max-width: 750px;
}

.event-save-modal h4 {
    color: #000000;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    padding-top: 30px;
    margin-bottom: 60px;
}

:host ::ng-deep.mat-dialog-content {
    overflow-y: scroll !important;
    max-height: 650px !important;
}

.overflow-dialog .mat-dialog-content {
    overflow-y: scroll !important;
    max-height: 650px !important;
}

.bdrop {
    backdrop-filter: blur(3px);
    z-index: 999 !important;
}

.modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    padding: 30px;
    -webkit-overflow-scrolling: touch;
}

.event-save-modal .modal-body {
    text-align: center;
}

.event-save-modal .btn-signup {
    width: 54%;
}

.circle-green {
    width: 250px;
    height: 250px;
    background-color: #248097;
    border-radius: 50%;
    display: inline-block;
    padding-top: 40px;
}

.circle-green img {
    width: 160px;
}

.add-event-modal h3,
.add-client-modal h3,
.situational-modal h3 {
    color: #142e4b;
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 40px;
}

.form-select {
    outline: none;
    color: #6c757d;
    appearance: none;
    background-image: url('./assets/images/svg/arrow_drop_down.svg') !important;
    background-size: 28px 28px;
}

.add-event-modal textarea,
.add-client-modal textarea {
    height: 142px;
}

.add-event-modal .width50 {
    width: 48%;
}

.location-control {
    background: #f3f3f3 url('./assets/images/location-icon.png') 15px 12px no-repeat;
    padding-left: 52px;
}

.add-event-modal h5,
.add-client-modal h5 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 7px;
}

.form-date {
    width: 50%;
}

.form-date .form-control {
    background: #f3f3f3 url('./assets/images/calender-icon.png') right top no-repeat;
}

.form-time {
    width: 47%;
}

.form-time .width50 {
    width: 47%;
}

.dropdown-toggle .green-dp {
    width: 29px;
    height: 29px;
    background-color: #14abb5;
    border: 1px #0e767d solid;
    border-radius: 50px;
    display: inline-block;
}

.add-event-modal .dropdown {
    padding-top: 30px;
}

.add-event-modal .dropdown-toggle:after {
    position: absolute;
    top: 46px;
    margin-left: 0.555em;
    border-top: 0.6em solid;
    border-right: 0.6em solid transparent;
    border-left: 0.6em solid transparent;
}

.add-event-modal .dropdown-toggle {
    border: 0;
}

.add-event-modal .dropdown-menu li {
    padding: 5px 10px;
    display: inline-block;
    text-align: center;
}

.add-event-modal .dropdown-menu .red-dp {
    width: 29px;
    height: 29px;
    background-color: red;
    border-radius: 50px;
    display: block;
}

.add-event-modal .dropdown-menu .red-dp.blue-dp {
    background-color: blue;
}

.add-event-modal .dropdown-menu .red-dp.pink-dp {
    background-color: pink;
}

.add-event-modal .dropdown-menu {
    min-width: 70px;
    text-align: center;
}

.add-client-modal .form-time {
    width: 100%;
}

.add-client-modal .form-time .width50 {
    width: 48%;
}

.balance-dp {
    background: #f3f3f3 url('./assets/images/dollar-icon.png') left top no-repeat;
    padding-left: 70px;
}

.add-client-modal .form-date {
    width: 100%;
}

.footer-btn .btn {
    width: 48%;
}

.add-client-modal textarea {
    font-style: italic;
}

.client-table {
    border: 1px #d5d5d5 solid;
}

.client-table thead th {
    background-color: #2c677b;
    font-size: 1.1rem;
    font-weight: 400;
    color: #fff;
    padding: 15px 15px;
}

.client-table td {
    font-size: 1.1rem;
    font-weight: 400;
    color: #5a5858;
    padding: 15px 15px;
}

.client-referrals {
    padding-top: 0px;
}

.client-referrals h2 {
    font-size: 1.7rem;
    color: #2c677b;
}

.referrals-search-icon ul {
    display: flex;
}

.referrals-search-icon li {
    padding: 0 12px;
}

.referrals-search-icon li a i {
    color: #2c677b;
    font-size: 1.6rem;
}

a.line-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;
}

.line-icon span {
    width: 25px;
    height: 3px;
    background-color: #2c677b;
    display: inline-block;
}

.line-icon span:nth-child(2) {
    width: 17px;
    margin: 3px 0;
}

.line-icon span:nth-child(3) {
    width: 15px;
}

.client-table .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
}

.pagi-arrow ul {
    display: flex;
}

.row-page {
    display: flex;
    align-items: center;
}

.row-page select {
    font-size: 1rem;
    min-width: 35px;
    width: 68px;
    height: 40px;
    font-weight: 600;
    margin: 0 18px;
}

.row-page-number {
    width: 90px;
    text-align: center;
    font-weight: 600;
}

.pagi-arrow li {
    padding: 0 25px;
}

.pagi-arrow li a {
    border: 0;
    color: #b7bfc7;
    font-size: 17px;
}

.setting-btn {
    display: flex;
}

.setting-btn {
    display: flex;
}

.setting-btn a {
    width: 190px;
    margin-left: 10px;
}

.setting-tab {
    padding-top: 1rem;
}

.setting-tab .nav-link {
    font-size: 1rem;
    color: #b8c0c9;
    padding: 0;
    margin-right: 2.9rem;
    font-weight: 500;
}

.setting-tab .nav-link.active {
    background-color: transparent;
    color: #2c677b;
}

.setting-tab .tab-content {
    padding-top: 50px;
}

.setting-tab .tab-content h3 {
    color: #142e4b;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 30px;
}

#pills-display h4 {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 18px;
}

.font-heading label {
    font-weight: 400;
    color: #5a5858;
    font-size: 0.7rem;
    min-height: 40px;
    margin-bottom: 0;
    line-height: 1.2rem;
}

.font-heading label:nth-child(3) {
    font-size: 1.2rem;
}

.font-heading label:nth-child(4) {
    font-size: 1.8rem;
}

.company-tab {
    width: 100%;
    display: flex;
}

.company-column {
    width: 166px;
    margin-right: 50px;
}

.company-columnright {
    width: 560px;
}

.company-columnright .form-time {
    width: 100%;
}

.company-columnright .form-time .width50 {
    width: 48%;
}

.profile-img {
    width: 100%;
    padding-top: 8px;
}

.profile-img img {
    border: 1px #a6a6a6 solid;
    border-radius: 8px;
    width: 100%;
}

.upload-btn {
    font-size: 1rem;
    font-weight: 400;
    text-decoration: none;
    color: #000;
    text-align: center;
    width: 100%;
    display: inline-block;
    padding-top: 14px;
}

.upload-btn span {
    background: url('./assets/images/upload-icon.png') left 5px no-repeat;
    padding: 2px 20px 2px;
}

.container-checkbox {
    display: block;
    position: relative;
    padding-left: 50px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 15px;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px #2c677b solid;
    border-radius: 3px;
}

.container-checkbox:hover input ~ .checkmark {
    background-color: #fff;
    cursor: pointer;
}

.container-checkbox input:checked ~ .checkmark {
    background-color: #fff;
}

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

.container-checkbox input:checked ~ .checkmark:after {
    display: block;
}

.container-checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid #2c677b;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.form-card {
    text-align: left;
}

#msform {
    position: relative;
}

.form-card .form-time {
    width: 100%;
}

.form-card .form-time .width50 {
    width: 48%;
}

#msform fieldset {
    background: white;
    border: 0 none;
    border-radius: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    margin: 0;

    position: relative;
}

#msform fieldset:not(:first-of-type) {
    display: none;
}

#msform .action-button-previous {
    height: 53px;
    background-color: #fff;
    font-weight: 600;
    color: #000;
    font-size: 1rem;
    border: 1px #9fccaf solid;
    display: inline-block;
    border-radius: 0.375rem;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    line-height: 2.5rem;
    padding: 0.375rem 0.75rem;
    width: 48%;
}

input.next.action-button,
.submit-application {
    height: 53px;
    background-color: #248097;
    font-weight: 600;
    color: #000;
    width: 48%;
    font-size: 1rem;
    display: inline-block;
    border-radius: 0.375rem;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    line-height: 2.5rem;
    padding: 0.375rem 0.75rem;
    border: 0;
    margin-left: 2%;
}

input.next.action-button:hover,
.submit-application:hover {
    background-color: #142e4b;
    color: #fff;
    border-color: #142e4b;
    cursor: pointer;
}

.card {
    z-index: 0;
    border: none;
    border-radius: 0.5rem;
    position: relative;
}

.fs-title {
    font-size: 25px;
    color: #2c3e50;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: left;
}

#progressbar {
    overflow: hidden;
    color: lightgrey;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 170px;
}

#progressbar .active {
    color: #000000;
}

#progressbar li {
    list-style-type: none;
    font-size: 12px;
    width: 33%;
    float: left;
    position: relative;
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px;
}

#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 17px;
    z-index: -1;
}

#progressbar li strong {
    width: 35px;
    height: 35px;
    background-color: #248097;
    border: 1px #83b294 solid;
    display: inline-block;
    font-size: 1.3rem;
    border-radius: 50px;
    color: #fff;
}

#progressbar li.active strong {
    background-color: #142e4b;
    border-color: #142e4b;
    color: #248097;
}

#msform fieldset .btn-signup {
    width: 48%;
}

#msform fieldset .locations .btn-signup {
    width: 100%;
}

#msform fieldset h4 {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 30px;
}

.col-50 {
    width: 49%;
    float: left;
}

.nav-dots-bl {
    position: absolute;
    bottom: 20px;
    text-align: center;
    left: 0;
    width: 100%;
    display: none;
}
.footer {
    height: 50px;
    bottom: 0;
    line-height: 50px;
    position: absolute;
    right: 0;
    left: 20px;
    background-color: transparent;
}
.content {
    min-height: calc(100vh - 70px);
}

.task-red {
    background: -webkit-linear-gradient(315deg, #eb4511 0%, #b02e0c 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.task-orange {
    background: -webkit-linear-gradient(315deg, #ff4e00 0%, #ec9f05 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.task-purple {
    background: -webkit-linear-gradient(315deg, #6617cb 0%, #cb218e 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.task-green {
    background: -webkit-linear-gradient(315deg, #2c677b 0%, #248097 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.task-blue {
    background: -webkit-linear-gradient(315deg, #004bee 0%, #001858 74%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.e-dashboardlayout.e-control.e-responsive {
    z-index: 1 !important;
}

i.pulse {
    text-decoration: none;
    color: #175a6b;
}

i.pulse.fa-lock:after {
    content: '\f023';
    display: block;
}

i.pulse.fa-lock:before {
    position: absolute;
}

i.pulse:before {
    -webkit-animation: pulse 2s ease-in !important;
    -moz-animation: pulse 2s ease-in !important;
    animation: pulse 2s ease-in !important;
    -webkit-animation-iteration-count: infinite !important;
    -moz-animation-iteration-count: infinite !important;
    animation-iteration-count: infinite !important;
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1) !important;
        opacity: 0 !important;
    }

    25% {
        -webkit-transform: scale(1.35) !important;
        opacity: 0.1 !important;
    }

    50% {
        -webkit-transform: scale(1.7) !important;
        opacity: 0.3 !important;
    }

    75% {
        -webkit-transform: scale(2.05) !important;
        opacity: 0.5 !important;
    }

    100% {
        -webkit-transform: scale(2.4) !important;
        opacity: 0 !important;
    }
}

/* Add these styles for ngbDropdown menus */
.dropdown-menu.show {
  z-index: var(--z-dropdown) !important;
  position: absolute !important;
}

/* Ensure the dropdown container has proper positioning */
[ngbDropdown] {
  position: relative !important;
  z-index: var(--z-dropdown) !important;
}

/* Style header dropdowns specifically */
header [ngbDropdown] .dropdown-menu.show {
  position: absolute !important;
  z-index: var(--z-dropdown) !important;
  margin-top: 5px !important;
  background: white !important;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1) !important;
}

/* Ensure header maintains proper stacking context */
header {
  position: fixed;
  z-index: var(--z-header) !important;
  /* ... existing header styles ... */
}

/* Fix for any nested dropdown content */
.dropdown-menu * {
  z-index: var(--z-dropdown) !important;
}

/* Add these global fixes */
:root {
    --vh: 1vh;
}

html {
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

/* Fix for Material components in Safari */
.mat-form-field {
    width: 100%;
    max-width: 100%;
}

.mat-drawer-container {
    position: relative;
    z-index: auto;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    display: block;
    overflow: hidden;
}

/* Fix for modal dialogs */
.cdk-overlay-container {
    position: fixed;
    z-index: 1000;
}

/* Fix for scrolling containers */
.scrollable-container {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    position: relative;
}
