@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  75% {
    transform: translateX(4%);
  }
  90% {
    transform: translateX(2%);
  }
  100% {
    transform: translateX(0);
  }
}
