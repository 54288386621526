@import 'assets/css/z-index.css';
@import "vars";
@import "base";
@import "global";
@import "dark-theme";
@import "animations";
@import 'ngx-toastr/toastr';

.error-details-modal-container {
  /* Using centralized z-index management */
  z-index: var(--z-modal) !important;

  .mat-dialog-container {
    transform: translate(-50%, -50%); /* Center the dialog */
    position: relative;
  }
}

.error-details-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-modal-backdrop) !important;
}

.cdk-global-overlay-wrapper {
  z-index: var(--z-cdk-overlay) !important;
}

// Global message styles
.ant-message {
    z-index: var(--z-notification) !important;

    .ant-message-notice-content {
        padding: 12px 24px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
            0 9px 28px 8px rgba(0, 0, 0, 0.05);
        border-radius: 4px;

        // Success message
        .ant-message-success {
            .anticon {
                color: #52c41a;
            }
        }

        // Error message
        .ant-message-error {
            .anticon {
                color: #ff4d4f;
            }
        }

        // Warning message
        .ant-message-warning {
            .anticon {
                color: #faad14;
            }
        }

        // Info message
        .ant-message-info {
            .anticon {
                color: #1890ff;
            }
        }
    }
}

// Message animation
.ant-message-notice.move-up-leave.move-up-leave-active {
    animation-name: MessageMoveOut;
    animation-duration: 0.3s;
}

@keyframes MessageMoveOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(100%);
    }
}