/* Z-Index Management System */
:root {
    /* Base Layers (0-99) */
    --z-base: 0;
    --z-card: 0;
    --z-progress: 2;
    --z-dashboard: 1;

    /* Navigation & UI Elements (100-999) */
    --z-header: 100;
    --z-nav: 200;
    --z-dropdown: 300;
    --z-sticky: 400;
    --z-fixed: 500;

    /* Overlays & Modals (1000-1999) */
    --z-overlay-base: 1000;
    --z-dropdown-menu: 1000;
    --z-cdk-overlay: 1000;
    --z-dialog-base: 1001;
    --z-modal-backdrop: 1100;
    --z-modal: 1200;
    --z-modal-container: 1201;
    --z-popconfirm: 1400;
    --z-dialog: 1500;
    --z-toast: 1600;
    --z-tooltip: 1700;

    /* Top Level Elements (2000+) */
    --z-popover: 2000;
    --z-notification: 2100;
    --z-message: 2200;
}

/* Base Elements */
.card {
    z-index: var(--z-card);
}

#progressbar {
    z-index: var(--z-progress);
}

.e-dashboardlayout.e-control.e-responsive {
    z-index: var(--z-dashboard) !important;
}

/* Navigation & Headers */
header {
    z-index: var(--z-header) !important;
}

/* Dropdowns */
[ngbDropdown] {
    position: relative !important;
    z-index: var(--z-dropdown) !important;
}

.dropdown-menu.show {
    z-index: var(--z-dropdown-menu) !important;
}

header [ngbDropdown] .dropdown-menu.show {
    z-index: var(--z-dropdown-menu) !important;
}

.dropdown-menu * {
    z-index: var(--z-dialog-base) !important;
}

/* Dialogs & Modals */
.e-dlg-container.e-kanban-dialog.e-dlg-center-center,
.e-popup.e-popup-open.e-dialog,
.e-lib.e-dialog.e-control.e-kanban-dialog.e-dlg-modal.e-popup.e-popup-open {
    z-index: var(--z-dialog-base) !important;
}

/* Messages & Notifications */
.ant-message {
    z-index: var(--z-message) !important;
}

.ant-message-notice-content {
    position: relative;
    z-index: var(--z-message);
}

/* Overlay Container */
.cdk-overlay-container,
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    z-index: var(--z-cdk-overlay) !important;
}

/* Fixed Position Elements */
.fixed-top,
.fixed-bottom {
    z-index: var(--z-fixed);
}

/* Sticky Elements */
.sticky-top,
.sticky-bottom {
    z-index: var(--z-sticky);
}

/* Add Popconfirm styles */
.ant-popover.ant-popconfirm {
    z-index: var(--z-popconfirm) !important;
}

.ant-popover-content,
.ant-popover-inner,
.ant-popover.ant-popconfirm .ant-popover-inner-content {
    z-index: var(--z-popconfirm) !important;
}

/* Ensure the overlay container for popconfirm is above modal */
.cdk-overlay-container .ant-popover-open {
    z-index: var(--z-popconfirm) !important;
}
