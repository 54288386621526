:root {
    --light-purple: #{$light-purple};
    --dark-purple: #{$dark-purple};
    --bg-box: #{$light-bg};
    --sidebar-bg: #{$light-sidebar-bg};
    --body-bg: #{$light-body-bg};
    --body-text: #{$light-body-text};
    --btn-text: #{$light-btn-text};
    --dropdown-checkbox: #{$light-dropdown-checkbox};
    --dropdown-shadow: #{$light-dropdown-shadow};
    --dropdown-bg: #{$light-bg};
    --table-bg: #{$light-table-bg};
    --invoice-bg: #{$light-bg};
    --btn-bg: #{$light-table-bg};
    --actions-bg: #{$light-bg};
    --total-amout-bg: #{$light-sidebar-bg};
    --status-draft: #{hexToRGB($light-status-draft)};
    --bg-form: #{$light-bg};
    --color-border-input: #{$light-dropdown-checkbox};
}

.theme-animation {
    transition: background 0.3s ease 0s;
}

.flex-center,
.flex-between {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.flex-center {
    justify-content: center;
}

.flex-between {
    justify-content: space-between;
}
.flex-around {
    display: flex;
    justify-content: space-around !important;
}
.status-text > p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    text-transform: capitalize;
    width: 105px;
    height: 40px;
    margin-bottom: 0px !important;
}

.status-paid,
.status-pending,
.status-draft {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    text-transform: capitalize;
    width: 105px;
    height: 40px;

    &:before {
        content: '';
        display: inline-block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background: inherit;
        margin-right: 0.3rem;
        margin-bottom: 0.1rem;
    }
}

.status-paid {
    color: $status-paid;
    background: rgba($status-paid, 0.05);

    &:before {
        background: $status-paid;
    }
}

.status-pending {
    color: $status-pending;
    background: rgba($status-pending, 0.05);

    &:before {
        background: $status-pending;
    }
}

.status-draft {
    color: rgba(var(--status-draft), 1);
    background: rgba(var(--status-draft), 0.05);

    &:before {
        background: rgba(var(--status-draft), 1);
    }
}

.btn {
    background: var(--btn-bg);
    border-radius: 2rem;
    color: var(--btn-text);
    font-weight: 700;
    font-size: 0.75rem;
    padding: 0 1.5rem;
    min-width: 90px;
    cursor: pointer;
    transition: color 0.3s ease 0s, border 0.3s ease 0s, background 0.3s ease 0s;

    &-delete {
        background: hsl(0, 80%, 63%);
        color: #fff;
    }
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.backdrop {
    overflow-y: hidden;
}
