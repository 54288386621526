/*! common core */
.e-control,
.e-css {
    font-family: 'Segoe UI', 'GeezaPro', 'DejaVu Serif', sans-serif, '-apple-system', 'BlinkMacSystemFont';
    font-size: 14px;
    font-weight: normal;
}

.e-error {
    color: #a80000;
    font-family: 'Segoe UI', 'GeezaPro', 'DejaVu Serif', sans-serif, '-apple-system', 'BlinkMacSystemFont';
    font-size: 14px;
    font-weight: normal;
}

.e-control,
.e-control [class^='e-'],
.e-control [class*=' e-'] {
    box-sizing: border-box;
}

.e-control:focus,
.e-control *:focus {
    outline: none;
}

.e-rtl {
    direction: rtl;
    text-align: right;
}

.e-overlay {
    background-color: #383838;
    filter: alpha(opacity=50);
    height: 100%;
    opacity: 0.5;
    pointer-events: none;
    touch-action: none;
    width: 100%;
}

.e-hidden {
    display: none;
}

.e-blazor-hidden {
    visibility: hidden;
}

.e-disabled {
    background-image: none;
    cursor: default;
    filter: alpha(Opacity=35);
    opacity: 0.35;
}

.e-ul {
    list-style-type: none;
}

.e-prevent-select {
    user-select: none;
}

.e-warning {
    color: #d83b01;
}

.e-success {
    color: #107c10;
}

.e-information {
    color: #333;
}

.e-block-touch {
    touch-action: pinch-zoom;
}

.e-license {
    color: #ffff00;
    text-decoration: none;
}

.e-license-banner {
    position: absolute;
    right: 10px;
    top: 27%;
    cursor: pointer;
}

/*! animation keyframes */
@keyframes SlideLeftOut {
    from {
        transform: translate(0, 0);
    }
    to {
        transform: translate(-100%, 0);
    }
}

@keyframes SlideLeftIn {
    from {
        transform: translate(-100%, 0);
    }
    to {
        transform: translate(0, 0);
    }
}

@keyframes SlideRightIn {
    from {
        transform: translate(100%, 0);
    }
    to {
        transform: translate(0, 0);
    }
}

@keyframes SlideRightOut {
    from {
        transform: translate(0, 0);
    }
    to {
        transform: translate(100%, 0);
    }
}

@keyframes SlideBottomIn {
    from {
        transform: translate(0, 100%);
    }
    to {
        transform: translate(0, 0);
    }
}

@keyframes SlideBottomOut {
    from {
        transform: translate(0, 0);
    }
    to {
        transform: translate(0, 100%);
    }
}

@keyframes SlideTopIn {
    from {
        transform: translate(0, -100%);
    }
    to {
        transform: translate(0, 0);
    }
}

@keyframes SlideTopOut {
    from {
        transform: translate(0, 0);
    }
    to {
        transform: translate(0, -100%);
    }
}

@keyframes SlideRight {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes SlideLeft {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}

@keyframes SlideDown {
    from {
        height: 0;
    }
    to {
        height: 100%;
    }
}

@keyframes SlideUp {
    from {
        height: 100%;
    }
    to {
        height: 0;
    }
}

@keyframes FadeIn {
    0% {
        filter: alpha(opacity=0);
        opacity: 0;
    }
    100% {
        filter: alpha(opacity=100);
        opacity: 1;
    }
}

@keyframes FadeOut {
    from {
        filter: alpha(opacity=100);
        opacity: 1;
    }
    to {
        filter: alpha(opacity=0);
        opacity: 0;
    }
}

@keyframes ZoomIn {
    from {
        transform: translate(0, 0) scale(0);
    }
    to {
        transform: translate(0, 0) scale(1);
    }
}

@keyframes ZoomOut {
    from {
        transform: translate(0, 0) scale(1);
    }
    to {
        transform: translate(0, 0) scale(0);
    }
}

@keyframes FadeZoomIn {
    from {
        filter: alpha(opacity=0);
        opacity: 0;
        transform: scale(0);
    }
    to {
        filter: alpha(opacity=100);
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes FadeZoomOut {
    from {
        filter: alpha(opacity=100);
        opacity: 1;
        transform: scale(1);
    }
    to {
        filter: alpha(opacity=0);
        opacity: 0;
        transform: scale(0);
    }
}

@keyframes FlipRightDownIn {
    from {
        transform: perspective(400px) rotateY(-180deg);
        transform-origin: right center;
        transform-style: preserve-3d;
    }
    to {
        transform: rotateY(0deg);
        transform-origin: right center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipRightDownOut {
    from {
        transform: perspective(400px) rotateY(0deg);
        transform-origin: right center;
        transform-style: preserve-3d;
    }
    to {
        transform: rotateY(-180deg);
        transform-origin: right center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipRightUpIn {
    from {
        transform: perspective(400px) rotateY(135deg);
        transform-origin: right center;
        transform-style: preserve-3d;
    }
    to {
        transform: rotateY(0deg);
        transform-origin: right center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipRightUpOut {
    from {
        transform: perspective(400px) rotateY(0deg);
        transform-origin: right center;
        transform-style: preserve-3d;
    }
    to {
        transform: rotateY(135deg);
        transform-origin: right center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipLeftDownIn {
    from {
        transform: perspective(400px) rotateY(-180deg);
        transform-origin: left center;
        transform-style: preserve-3d;
    }
    to {
        transform: rotateY(0deg);
        transform-origin: left center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipLeftDownOut {
    from {
        transform: perspective(400px) rotateY(0deg);
        transform-origin: left center;
        transform-style: preserve-3d;
    }
    to {
        transform: rotateY(135deg);
        transform-origin: left center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipLeftUpIn {
    from {
        transform: perspective(400px) rotateY(-135deg);
        transform-origin: left center;
        transform-style: preserve-3d;
    }
    to {
        transform: rotateY(0deg);
        transform-origin: left center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipLeftUpOut {
    from {
        transform: perspective(400px) rotateY(0deg);
        transform-origin: left center;
        transform-style: preserve-3d;
    }
    to {
        transform: rotateY(135deg) perspective(200px);
        transform-origin: left center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipYLeftIn {
    from {
        filter: alpha(opacity=0);
        opacity: 0;
        transform: perspective(400px) rotateY(180deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
    50% {
        transform: perspective(700px) rotateY(90deg);
    }
    to {
        filter: alpha(opacity=100);
        opacity: 1;
        transform: rotateY(0deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipYLeftOut {
    from {
        filter: alpha(opacity=100);
        opacity: 1;
        transform: perspective(400px) rotateY(0deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
    50% {
        transform: perspective(700px) rotateY(90deg);
    }
    75% {
        transform: perspective(850px) rotateY(125deg);
    }
    to {
        filter: alpha(opacity=0);
        opacity: 0;
        transform: rotateY(180deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipYRightIn {
    from {
        filter: alpha(opacity=0);
        opacity: 0;
        transform: perspective(400px) rotateY(-180deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
    50% {
        transform: perspective(700px) rotateY(-90deg);
    }
    to {
        filter: alpha(opacity=100);
        opacity: 1;
        transform: rotateY(0deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipYRightOut {
    from {
        filter: alpha(opacity=100);
        opacity: 1;
        transform: perspective(400px) rotateY(0deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
    50% {
        transform: perspective(700px) rotateY(-90deg);
    }
    to {
        filter: alpha(opacity=0);
        opacity: 0;
        transform: rotateY(-180deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipXDownIn {
    from {
        filter: alpha(opacity=0);
        opacity: 0;
        transform: perspective(400px) rotateX(180deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
    50% {
        transform: perspective(700px) rotateX(90deg);
    }
    to {
        filter: alpha(opacity=100);
        opacity: 1;
        transform: rotateX(0deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipXDownOut {
    from {
        filter: alpha(opacity=100);
        opacity: 1;
        transform: perspective(400px) rotateX(0deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
    50% {
        transform: perspective(700px) rotateX(90deg);
    }
    75% {
        transform: perspective(850px) rotateX(125deg);
    }
    to {
        filter: alpha(opacity=0);
        opacity: 0;
        transform: rotateX(180deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipXUpIn {
    from {
        filter: alpha(opacity=0);
        opacity: 0;
        transform: perspective(400px) rotateX(-180deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
    50% {
        transform: perspective(700px) rotateX(-90deg);
    }
    to {
        filter: alpha(opacity=100);
        opacity: 1;
        transform: rotateX(0deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
}

@keyframes FlipXUpOut {
    from {
        filter: alpha(opacity=100);
        opacity: 1;
        transform: perspective(400px) rotateX(0deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
    50% {
        transform: perspective(700px) rotateX(-90deg);
    }
    to {
        filter: alpha(opacity=0);
        opacity: 0;
        transform: rotateX(-180deg);
        transform-origin: center center;
        transform-style: preserve-3d;
    }
}

.e-ripple,
.e-ripple-style {
    overflow: hidden;
    position: relative;
    transform: translate3d(0, 0, 0);
}

.e-ripple-element,
.e-ripple-style::after {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    transform: scale(0);
    transition: opacity, transform 0ms cubic-bezier(0, 0.1, 0.2, 1);
}

.e-ripple-style::after {
    background: rgba(0, 0, 0, 0.3);
    content: '';
    display: block;
    height: 50%;
    left: 25%;
    opacity: 0;
    top: 25%;
    transform: scale(3);
    transition: transform 0.5s, opacity 0.5s;
    width: 50%;
}

.e-ripple-style:active::after {
    opacity: 1;
    transform: scale(0);
    transition: 0s;
}

.required:after{
    content:"*";
    font-weight:bold;
    color: red;
    margin-left: 5px;
}
 
figure {
    float: left;
    background: #f3f3f3;
    font-size: 50px;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 50%;
    height: 66px;
    width: 66px;
    overflow: hidden;
}

figure img {
    display: block;
    height: auto;
    width: 100%;
}
